<template>
  <div class="ma-10">
  <!-- modal showing detail -->
  <Modal v-if="isModalDetail" @close="() => (isModalDetail = false)" width="40%">
    <p class="modal-title">Detail Transaksi User</p>

    <div class="modal-body">
      <div class="data-list">

        <div class="detail-data">
          <p class="detail-label">No. Invoice</p>
          <p class="detail-value">{{ transactionDetail.invoice_number }}</p>
        </div>
        <div class="detail-data">
          <p class="detail-label">Tanggal Pembelian</p>
          <p class="detail-value">{{ transactionDetail.created_at }}</p>
        </div>

        <div class="detail-data collapse" 
          @click="() => collapse.customer = !collapse.customer">
          <p class="detail-label">Pelangan</p>
          <Arrow :down="!collapse.customer"/>
        </div>
        <div class="data-list" v-if="collapse.customer">
          <div class="detail-data">
            <p class="detail-label">Nama</p>
            <p class="detail-value">{{ transactionDetail.full_name }}</p>
          </div>
          <div class="detail-data">
            <p class="detail-label">No. handphone</p>
            <p class="detail-value">(+62) {{ transactionDetail.phone_number }}</p>
          </div>
          <div class="detail-data">
            <p class="detail-label">Email</p>
            <p class="detail-value">{{ transactionDetail.email }}</p>
          </div>
        </div>

        <div class="detail-data collapse" 
          @click="() => collapse.product = !collapse.product">
          <p class="detail-label">Produk</p>
          <Arrow :down="!collapse.product"/>
        </div>
        <div class="data-list" v-if="collapse.product">
          <div class="detail-data">
            <p class="detail-label">ID Produk</p>
            <p class="detail-value">{{ transactionDetail.class.id }}</p>
          </div>
          <div class="detail-data">
            <p class="detail-label">Program</p>
            <p class="detail-value">{{ transactionDetail.class.name }}</p>
          </div>
          <div class="detail-data">
            <p class="detail-label">Harga</p>
            <p class="detail-value">{{ transactionDetail.price }}</p>
          </div>
        </div>

        <div class="detail-data collapse" 
          @click="() => collapse.transaction = !collapse.transaction">
          <p class="detail-label">Transaksi</p>
          <Arrow :down="!collapse.transaction"/>
        </div>
        <div class="data-list" v-if="collapse.transaction">
          <div class="detail-data">
            <p class="detail-label">Kode Voucher</p>
            <p class="detail-value">{{ transactionDetail.voucher_code }}</p>
          </div>
          <div class="detail-data">
            <p class="detail-label">Diskon</p>
            <p class="detail-value discount">{{ transactionDetail.discount }}</p>
          </div>
          <div class="detail-data">
            <p class="detail-label">Total Pembayaran</p>
            <p class="detail-value total">{{ transactionDetail.total_price }}</p>
          </div>
          <div class="detail-data">
            <p class="detail-label">Status</p>
            <p class="detail-value">{{ transactionDetail.status }}</p>
          </div>
        </div>

        <div class="detail-data collapse" 
          @click="() => collapse.logs = !collapse.logs">
          <p class="detail-label">Riwayat Transaksi</p>
          <Arrow :down="!collapse.logs"/>
        </div>
        <div class="data-list" v-if="collapse.logs">
          <div class="log-item" v-for="(item, idx) in transactionLog" :key="idx">
            <BlueBullet />
            <div class="log-data">
              <div v-if="idx != transactionLog.length-1" class="connector"></div>
              <p>{{ formatDateLog(item.created_at) }}</p>
              <p>{{ item.status | toMessageLog }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Modal>

  <!-- modal download -->
    <Modal v-if="isModal" @close="() => (isModal = false)" width="40%">
      <p class="modal-title">Konfirmasi</p>
      <p>Apakah Anda yakin akan mengunduh data transaksi?</p>
      <div style="display: flex; gap: 20px; margin-top: 30px">
        <Button name="YA, UNDUH" width="100%" @click="importExcelData" />
        <Button
          name="TIDAK, KEMBALI"
          width="100%"
          outline
          @click="() => (isModal = false)"
        />
      </div>
    </Modal>
    <div class="title-page">
      <h1 class="mb-4">PUBLIC TRAINING</h1>
      <Button name="DOWNLOAD" outline @click="isModal = true" width="180px"><Download /></Button>
    </div>
    <div class="container-box">
      <v-row class="filter-ctn">
        <v-col>
          <span class="">Cari</span>
          <v-text-field
            prepend-inner-icon="mdi-magnify"
            outlined
            v-model="filter.search"
            class="form-input"
            placeholder="Cari Nama / No. Invoice"
            dense
            clearable
          ></v-text-field>
        </v-col>
        <v-col>
          <span class="">Status Pembayaran</span>
          <v-select
            label="Status"
            v-model="filter.status"
            :items="statusList"
            :item-text="'name'"
            :item-value="'value'"
            class="form-input"
            solo
            dense
          ></v-select>
        </v-col>
        <v-col>
          <span class="">Tanggal Awal Transaksi</span>
          <v-text-field
            outlined
            v-model="filter.start_date"
            class="form-input"
            type="date"
            clearable
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <span class="">Tanggal Akhir Transaksi</span>
          <v-text-field
            outlined
            type="date"
            v-model="filter.end_date"
            class="form-input"
            clearable
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <div style="display: flex; gap: 10px">
        <Button name="TERAPKAN FILTER" @click="onFilter" width="200px" />
        <div class="reset-filter" @click="clearFilter" v-if="isFilterActive">
          RESET FILTER
        </div>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="transactionPublicTraining"
      sort-by="calories"
      class="elevation-1 mt-10 table"
      hide-default-footer
    >
      <template v-slot:item.status="{item}">
        {{ item.status | toMessageStatus }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row>
          <button @click="watchItem(item.id)" class="me-2">
            <WatchTable :height="28" :width="28" />
          </button>
          <button @click="editItem(item)" class="me-2">
            <EditTable :height="28" :width="28" />
          </button>
        </v-row>
      </template>
    </v-data-table>

    <v-row justify="center" v-if="total">
      <v-col cols="8">
        <v-container class="max-width">
          <v-pagination
            v-model="currentPage"
            class="my-4"
            :length="total"
            @input="getTransactionPublicTraining"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import Button from "@/components/Button.vue";
import Download from "@/components/icons/Download.vue";
import BlueBullet from "@/components/icons/BlueBullet.vue";
import Arrow from "@/components/icons/Arrow.vue";
import Modal from '@/components/Modal.vue';
import getAuthentification from "@/utils/badrequest.js";
import EditTable from "@/components/icons/EditTable.vue";
import WatchTable from "@/components/icons/WatchTable.vue";

export default {
  data() {
    return {
      currentPage: 1,
      total: 0,
      select: "all",
      isFilterActive: false,
      isModal: false,
      isModalDetail: false,
      transactionDetail: {},
      transactionLog: [],
      collapse: {
        customer: true,
        product: true,
        transaction: true,
        logs: true,
      },
      filter: {
        program: "",
        status: "",
        start_date: "",
        end_date: "",
        search: "",
      },
      trasnactionType: [
        {
          name: "Semua",
          value: "all",
        },
        {
          name: "Sudah bayar",
          value: "done",
        },
        {
          name: "Belum bayar",
          value: "progress",
        },
      ],
      programList: [
        {
          name: "Webinar",
          value: "webinar",
        },
        {
          name: "Mini Bootcamp",
          value: "mini-bootcamp",
        },
      ],
      statusList: [
        {
          name: "Draft",
          value: "DRAFT",
        },
        {
          name: "Menunggu Pembayaran",
          value: "WAITING_FOR_PAYMENT",
        },
        {
          name: "Sudah dibayar",
          value: "PAID",
        },
        {
          name: "Kadaluarsa",
          value: "EXPIRED",
        },
        {
          name: "Cancel",
          value: "CANCEL",
        },
      ],
      headers: [
        { text: 'NO. INVOICE', value: 'invoice_number', sortable: false },
        { text: 'TANGGAL PEMBELIAN', value: 'created_at', sortable: false, width: "180px" },
        { text: 'PELANGGAN', sortable: false, value: 'full_name' },
        { text: 'PROGRAM', value: 'class.name', sortable: false, width: "200px" },
        { text: 'VOUCHER', value: 'voucher_code', sortable: false },
        { text: 'NOMINAL TRANSAKSI', value: 'total_price', sortable: false, width: "180px" },
        { text: 'STATUS', value: 'status', sortable: false, width: "200px" },
        { text: 'Action', value: 'actions', width: "108px" },
      ],
      transactionPublicTraining: [],
    };
  },
  components: {
    Button,
    Download,
    Modal,
    BlueBullet,
    Arrow,
    EditTable,
    WatchTable
  },
  methods: {
    async getTransactionDetail(id) {
      await this.$web_http
        .get(
          `/v1/transaction/${id}?category=Public Training`
        )
        .then((response) => {
          const data = response.data.data.transaction
          this.transactionDetail = {
            ...data,
            created_at: this.$helpers.formatDateWithTimeAndTimeZone(data.created_at),
            total_price: data.total_price
              ? this.$helpers.formatCurrency(data.total_price)
              : "GRATIS",
            price: this.$helpers.formatCurrency(data.price),
            discount: data.discount? this.$helpers.formatCurrency(data.discount, true) : '-',
            status: this.toMessageStatus(data.status),
            voucher_code: data.voucher_code? data.voucher_code : '-',
          }
        })
        .catch(() => this.transactionDetail = {});
    },
    async getTransactionLog(id) {
      await this.$web_http
        .get(
          `/v1/transaction-log/transaction/${id}`
        )
        .then((response) => {
          this.transactionLog = response.data.data.transaction_logs
        })
        .catch(() => this.transactionDetail = {});
    },
    async getTransactionPublicTraining() {
      await this.$web_http
        .get(
          `/v1/transaction?category=Public Training&program=${this.filter.program}&status=${this.filter.status}&keyword=${this.filter.search}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&page=${this.currentPage}&per_page=20`
        )
        .then((response) => {
          this.transactionPublicTraining = response.data.data.transactions.map(
            (item) => ({
              ...item,
              created_at: moment(item.created_at).format("DD MMM YYYY HH:mm"),
              total_price: item.total_price
                ? this.toCurrency(item.total_price)
                : "GRATIS",
            })
          );
          this.total = parseInt(response.data.data.total / 20) + 1;
        })
        .catch(() => this.transactionPublicTraining = []);
    },
    onFilter() {
      this.currentPage = 1;
      this.checkFilter()
      this.getTransactionPublicTraining();
    },
    checkFilter() {
      if (this.filter.status || this.filter.start_date || this.filter.end_date || this.filter.search) {
        this.isFilterActive = true;  
      }
    },
    clearFilter() {
      this.isFilterActive = false;
      this.filter = {
        program: "",
        status: "",
        start_date: "",
        end_date: "",
        search: "",
      };
      this.getTransactionPublicTraining();
    },
    async importExcelData() {
      await this.$web_http
        .get("/v1/transaction/export?category=BOOTCAMP", {responseType: "blob"})
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/ms-excel",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "TransactionData.xlsx";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    async watchItem(id) {
      await this.getTransactionDetail(id);
      await this.getTransactionLog(id)
      this.isModalDetail = true;
    },
    formatDateLog(date) {
      return moment(date).format("DD/MM/YYYY, hh:mm") + " WIB"
    },
    editItem(item) {
      this.$router.push("/transaction/b2c/public-training/" + item.id);
    },
    toCurrency(val) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "IDR",
      });
      return formatter.format(val);
    },
    toMessageStatus(status) {
      for (let i = 0; i < this.statusList.length; i++) {
        if (this.statusList[i].value === status) {
          return this.statusList[i].name;
        }
      }
    },
  },
  beforeCreate() {
    getAuthentification();
  },
  mounted() {
    this.getTransactionPublicTraining();
  },
};
</script>
<style scoped>
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.modal-body {
  padding: 25px;
}
  .data-list {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.detail-data {
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
}
.detail-data p {
  margin: 0;
}
.detail-value {
  font-weight: 600;
}
.collapse {
  cursor: pointer;
  margin: 10px 0 10px 0;
}
.collapse .detail-label {
  font-weight: bolder;
}
.discount {
  color: #B80F0A;
}
.total {
  color: #162CA2;
}

.log-item {
  display: flex;
  gap: 10px;
}
.log-data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}
.log-data p {
  margin: 0;
  text-align: left;
}
.connector {
  width: 4px;
  height:  calc(100% - 14px);
  z-index: 1;
  background-color: #F1F6FF;
  left: -29px;
  top: 29px;
  position: absolute;
}
</style>
